(function ($) {
  if ($('.popup-with-zoom-anim').length > 0) {
    $('.popup-with-zoom-anim').magnificPopup({
      type: 'inline',

      fixedContentPos: false,
      fixedBgPos: true,

      overflowY: 'auto',

      closeBtnInside: true,
      preloader: false,

      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
    });
  }

  if ($('.with-dropdown').length > 0) {
    $('.with-dropdown').on('click', function () {
      $(this).toggleClass('is-active');
      $(this).next('.sub-menu').toggleClass('is-active');
      $('.with-dropdown').not(this).removeClass('is-active');
      $('.with-dropdown').not(this).next('.sub-menu').removeClass('is-active');
    });
  }

  // Toggle the Search in the header
  // Fix toggle for the mobile version
  if ($('.header-search').length > 0) {
    $('.header-search').on('click', function () {
      $(this).toggleClass('is-active');
      $('.header-search__container').addClass('animated slideInDown');
      $('.header-search__container').removeClass('slideOutUp');
      $('.hamburger').removeClass('is-active');
      $('body').removeClass('pushy-open-right');
    });

    $('.header-search__close').on('click', function () {
      $('.header-search__container').removeClass('slideInDown');
      $('.header-search__container').addClass('slideOutUp');
    });
  }
})(jQuery);

document.addEventListener('DOMContentLoaded', function() {
  const links = document.querySelectorAll('a.download-link');

  links.forEach(link => {
      const innerHTML = link.innerHTML;
      const updatedHTML = innerHTML.replace(/\(([^)]+)\)/, '<span>($1)</span>');
      link.innerHTML = updatedHTML;
      link.classList.add('dm-download-link');
  });
});

